<template>
  <div></div>
</template>

<script>
import {onMounted} from "vue";
import {goOtherAppPage} from "@/utils/goOtherApp";

export default {
  name: "middleware3",
  setup() {
    onMounted(() => {
      let opt = {
        type: 'Ordinary'
      }
      goOtherAppPage(opt);
    })
    return {}
  }
}
</script>

<style scoped>

</style>
